<template>
    <div class="contetnt_box" @click.stop="">
        <div class="my_search_box">
            <div class="search_left_box">
                <input placeholder="请输入商品名称/商品编号" v-model="keyWord" @focus="openPop" @input="chageKeyWord"
                    @keyup.enter="searchBtn">
                <!-- @blur="closePop" -->
            </div>
            <div class="right_search_btn_box" @click="searchBtn">
                立即搜索
            </div>
        </div>
        <!-- 搜索弹窗 -->
        <div class="search_pop_fixed_box" v-show="(popShow && showList.length) || isNoData">
            <div class="search_list_box">
                <div class="list" v-for="(item) in showList" :key="item.Id">
                    <div class="left_icon_box">
                        <i class="el-icon-search"></i>
                    </div>
                    <div class="center_text_box" @click="openListPage(item)">
                        {{ item.SearchContent }}
                    </div>
                    <div class="right_text_box">
                        {{ item.GameName }}
                        <div class="delete_btn" @click.prevent.stop="deleteHistoryFun(item)" v-show="!findGame">
                            <i class="el-icon-delete"></i>
                        </div>
                    </div>
                </div>
                <div class="noData_tip" v-show="isNoData">
                    点击搜索查看更多
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getHistoryList, createHistory, deleteHistory } from "~/api/user";

export default {
    data() {
        return {
            keyWord: "", // 搜索关键词
            userId: "", // 用户id
            popShow: false, //是否展示历史记录窗口

            allHistoryList: [], // 历史记录列表
            showList: [], // 展示使用的数据
            timer: null, // 节流定时器
            findGame: false, // 是否找到游戏
            isNoData: false, // 是否没有数据
        }
    },
    mounted() {
        if (localStorage.getItem('userInfo')) {
            this.userId = JSON.parse(localStorage.getItem('userInfo')).userId;
        }
        this.getHistory();
        // 页面每次显示的时候调用一次
        document.addEventListener('visibilitychange', this.pageShow)

        // 页面点击事件
        document.addEventListener('click', this.closePop)
    },

    // 注销绑定的事件
    beforeDestroy() {
        document.removeEventListener('visibilitychange', this.pageShow);
        document.removeEventListener('click', this.closePop);
    },


    methods: {
        // 页面显示事件
        pageShow() {
            if (document.visibilityState === "visible") {
                this.getHistory();
                this.popShow = false;
                this.isNoData = false;
            }
        },

        // 删除历史记录
        async deleteHistoryFun(item) {
            // console.log('删除历史记录', item);
            let result = await deleteHistory({
                method: "post",
                data: {
                    id: item.Id
                }
            })
            console.log('删除历史记录', result);
            if (result.data.code == 0) {
                this.getHistory();
            }
        },

        // 点击进入旧的页面
        openListPage(item) {
            // console.log('item', item);
            this.popShow = false;
            createHistory({
                method: 'post',
                data: {
                    gameId: item.GameId ? item.GameId : "",
                    gameName: item.GameId ? item.GameName : "",
                    userId: this.userId,
                    searchContent: item.SearchContent
                }
            })

            // if (result.data.code == 0) {
            //     this.getHistory();
            // }

            // 如果有游戏id 就去旧页面
            if (item.GameId) {
                window.localStorage.setItem('kw', item.SearchContent);
                window.open(`/wares/?gt=1&gid=${item.GameId}`);
            } else {
                window.open(`/waresNew?kw=${item.SearchContent}`);
            }

        },
        // 点击立即搜索
        searchBtn() {
            this.popShow = false;
            if (this.keyWord) {
                createHistory({
                    method: 'post',
                    data: {
                        userId: this.userId,
                        searchContent: this.keyWord
                    }
                })
                // if (result.data.code == 0) {
                //     this.getHistory();
                // }
                window.open(`/waresNew?kw=${this.keyWord}`);
            } else {
                this.$message({
                    message: '请输入内容再搜索',
                    type: 'warning'
                });
            }
        },
        // 搜索关键词改变事件
        chageKeyWord() {
            this.isNoData = false;
            this.keyWord = this.keyWord.trim();
            if (this.keyWord) {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.showList = [];
                    this.$store.state.web.gameListArray.forEach(item => {
                        if (item.name.includes(this.keyWord)) {
                            let obj = {
                                SearchContent: item.name,
                                GameId: item.id
                            }
                            this.showList.push(obj)
                        }
                    })
                    if (!this.keyWord) this.showList = [];
                    this.findGame = true;
                    // console.log('通过本地所有游戏名称搜索的结果', this.showList);
                    if (this.showList.length == 0) {
                        this.findGame = false;
                        let listArr = this.allHistoryList.filter(list => {
                            return list.SearchContent.includes(this.keyWord);
                        })
                        // console.log('通过匹配历史记录中的内容的结果', listArr);
                        this.showList = listArr;
                        if (this.showList.length == 0) {
                            this.isNoData = true;
                        }
                    }
                }, 250);
            } else {
                this.findGame = false;
                this.showList = this.allHistoryList;
            }
        },
        // 关闭历史记录接口
        closePop() {
            this.popShow = false;
            // setTimeout(() => {
            //     this.popShow = false;
            // }, 200);
        },
        // 打开历史记录窗口
        openPop() {
            this.popShow = true;
        },
        // 获取历史记录接口
        async getHistory() {
            let result = await getHistoryList({
                params: {
                    userId: this.userId
                }
            })
            console.log('历史记录接口', result);
            if (result.data.code == 0) {
                this.allHistoryList = result.data.data;
                this.showList = this.allHistoryList;
            }
        }
    }
}
</script>
<style lang="less" scoped>
.contetnt_box {
    width: 750px;
    position: relative;

    .my_search_box {
        display: flex;
        align-items: center;
        height: 40px;
        line-height: 40px;
        border-radius: 10px;
        overflow: hidden;
        border: 2px solid #2993ff;
        box-sizing: border-box;

        .search_left_box {
            background-color: #fff;
            padding: 0 20px;
            box-sizing: border-box;
            flex: 1;

            input {
                width: 100%;
                height: 100%;
                border: 0;
                outline: none;
                height: 30px;
                line-height: 30px;
            }
        }

        .right_search_btn_box {
            padding: 0 30px;
            color: #fff;
            background-color: #2993ff;

            &:hover {
                cursor: pointer;
            }
        }


    }

    .search_pop_fixed_box {
        position: absolute;
        top: 50px;
        left: 0;
        width: 750px;
        background-color: #fff;
        border: 1px solid #2993ff;
        box-sizing: border-box;
        border-radius: 10px;
        overflow: hidden;

        .search_list_box {
            display: flex;
            flex-direction: column;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            font-size: 20px;
            color: #2993FF;
            overflow-y: auto;
            max-height: 300px;
            // height: 300px;

            .list {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 20px;
                box-sizing: border-box;

                .left_icon_box {
                    color: #464646;
                }

                .center_text_box {
                    flex: 1;
                    margin: 0 10px;

                    &:hover {
                        cursor: pointer;
                    }

                }

                .right_text_box {
                    color: #525252;
                    display: flex;
                    align-items: center;

                    .delete_btn {
                        display: none;
                        color: red;
                        margin: 0 10px;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                &:hover {
                    // cursor: pointer;
                    background-color: #f2f2f2;

                    .delete_btn {
                        display: block;
                    }
                }

            }

            .noData_tip {
                padding: 10px 20px;
                box-sizing: border-box;
                text-align: center;
                color: #525252;
            }
        }
    }
}
</style>